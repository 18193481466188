.AllReviews {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 2em;
    &__title {
        background: white;
        color: black;
        padding: 0 5px 2px 5px;
        font-size: 1.5rem;
        margin-bottom: 1rem;
        display: inline-block;
        margin-bottom: 1rem;
    }
    &__review {
        display: flex;
        min-height: 100px;
        padding-bottom: 1em;
        &-info {
            display: flex;
            flex-direction: column;
            min-width: 175px;
            &-name {
                font-size: 1.25em;
                padding-bottom: 0.25em;
            }
            &-date {                    
                padding-bottom: 0.5em;
            }
        }
        &-text {
            padding-left: 0.5rem;
            line-height: 1.75em;
        }
        &-edit {
            cursor: pointer;
            margin-top: 0.5em;
            padding: 4px 8px;
            background-color: rgb(49, 54, 57);
            border-radius: 6px;
            display: inline-block;
            text-align: center;
        }
    }
    &__no-reviews {
        display: block;
        padding-bottom: 1em;
    }
}