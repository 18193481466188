.Login {
    min-width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Login__container {
    background-image:url('/images/vhs.png');
    background-repeat: no-repeat;
    background-size: contain;
    text-align: center;
    width: 320px;
    height: 180px;
}

.Login__form {
    width:320px;
    height: 180px;
    text-align: center;
    position: absolute;
}

.Login__form label:nth-child(1) {
    top:33%;
    left: 30%;
}

.Login__form label:nth-child(1) input {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: 1px solid black; 
}

.Login__form label:nth-child(2) {
    top:33%;
    left: 30%;
}

.Login__form label:nth-child(2) input {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}


.Login__form label {
    position: relative;
    display: flex;
    width: 42%;
    height: 38px;
}

.Login label span {
    flex:50%;
    width: 100px;
}

.Login label input {
    flex:50%;
    width: 100px;
}

.Login label input {
    border: none;
    background-color: rgba(255,250,250,.85);
    padding-left: 1rem;
    color: black;
}

.Login__notice {
    position: absolute;
    top: 5px;
    background-color: red;
    color: white;
    padding: 0 4px 0 4px;
}

.Login__submit {
    position: absolute;
    right: 15px;
    top: 33%;
    height: 76px;
    border-top-right-radius: 36px;
    border-top-left-radius: 6px;
    border-bottom-right-radius: 36px;
    border-bottom-left-radius: 6px;
    width: 67px;
    cursor: pointer;
    background-color: rgba(255,250,250,1);
}