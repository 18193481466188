.Invite {
    display: flex;
    justify-content: center;
    &__container {
        width: 100%;
        max-width: 1200px;
        background: black;
        padding: 16px 32px 32px 32px;
    }
    &__explainer {
        line-height: 1.5em;
        padding-bottom: 16px;
    }
    &__form {
        padding: 16px 32px;
        display: inline-block;
        width: 100%;
        box-sizing: border-box;
        &-name {
            display: flex;
            padding: 0 0 8px 0;
            &-label {
                display: inline-block;
                width: 60px;
            }
            &-input {
                width: 150px;
            }
        }
        &-email {
            display: flex;
            padding: 8px 0;
            &-label {
                display: inline-block;
                width: 60px;
            }
            &-input {
                width: 150px;
            }
        }
        &-howknow {
            &-label {
                display: inline-block;
                padding-top: 8px;
                padding-bottom: 8px;
            }
            &-input {
                display: block;
                width: 100%;
                height: 8rem;
            }
        }
        &-submit {
            padding: 4px 12px;
            font-size: 1.5em;
            background-color: #313639;
            display: inline-block;
            margin-top: 16px;
            cursor: pointer;
        }
    }
}