.Help {
    display : flex;
    justify-content: center;
    &__container {
        max-width: 1200px;
        background: black;
        padding: 16px 32px;
        font-size: 1.25em;
        box-sizing: border-box;
    }
    &__contact {
        display: inline-block;
        padding-top: 0;
        padding-bottom: 1rem;
    }
    &__section {
        display : block;
        &-title {
            background: white;
            color: black;
            padding: 0 5px 2px 5px;
            font-size: 1.5rem;
            margin-bottom: 1rem;
            display: inline-block;
            margin-bottom: 1rem;
        }
        &-entry {
            &-question {
                padding-left: 0.5rem;
                text-decoration: underline;
            }
            &-answer {
                font-size: 0.8em;
                padding-top: 0.5rem;
                padding-bottom: 1rem;
                padding-left: 1.5rem;
                line-height: 1.5em;
            }
        }
    }
    &__admin {
        &-select {

        }
        &-question {
            display: block;
        }
        &-answer {
            display: block;
        }
    }
}