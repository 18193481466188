.Bottom {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    flex-grow: 1;
}
.Footer {
    &__pusher {
        flex-grow: 1;
    }
    width: 100%;
    margin-top: 3rem;
    display: flex;
    justify-content: center;
    &__container {
        background: black;
        width: 100%;
        max-width: 1200px;
        padding-top: 1rem;
        padding-bottom: 2rem;
        padding-left: 1rem;
        padding-right: 1rem;
        display: flex; 
        justify-content: space-between;
        border-top: 5px solid #313639;
    }
    &__left {

    }
    &__right {
        text-align: right;
    }
    &__title {
        font-size: 1.5em;
    }
    &__content {
        padding-top: 0.5rem;
    }
    &__logout {
        cursor: pointer;
        display: inline-block;
        padding-top: 0.5rem;
    }
    a {
        color: white;
        text-decoration: none;
        &:visited {
            color: white;
        }
        &:hover {
            text-decoration: none;
        }
    }
}