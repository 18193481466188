.Devlog {
    display: flex;
    justify-content: center;
    &__container {
        width: 100%;
        max-width: 1200px;
        background: black;
        padding: 16px 32px;
        font-size: 1.25em;
        box-sizing: border-box;
    }
    &__log {
        padding-bottom: 32px;
        &-title {
            display: block;
        }
        &-date {
            font-size: 0.8rem;
            display: block;
            padding-bottom: 8px;
        }
        &-text {
            font-size: 1rem;
            line-height: 1.5em;
        }
    }
}