.HomePage {
    display: flex;
    justify-content: center;
    &__container {
        width: 100%;
        max-width: 1200px;
        background-color: black;
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
    }

    &__devlog {
        &-item {
            padding-top: 8px;
            padding-bottom: 8px;
            text-align: center;
            &-date {
                margin-top: 8px;
                margin-bottom: 16px;
                background: white;
                color: black;
                padding: 0 5px 2px 5px;
                font-size: 1.3rem;
                display: inline-block;
            }
            &-entry {
                &--devlog {
                    &-title {
                        font-size: 1.5em;
                    }
                    &-text {
                        font-size: 1em;
                        display: inline-block;
                        border-bottom: 2px solid white;
                    }
                }
            }
        }
    }

    &__box {
        &-title {
            padding-top: 1rem;
            font-size: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        flex-basis: 200px;
        box-sizing: border-box;
    }
    &__wide-box {
        padding-left: 1rem;
        padding-right: 1rem;
        &-title {
            font-size: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        flex: 1 0 200px;
        box-sizing: border-box;

    }
    &__newreviews {
        &-review {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-top: 2em;
            &-title {
                font-size: 1.2em;
                display: block;
                text-align: center;
            }
            &-text {
                display: block;
            }
        }
    }
    &__users {
        &-user {
            &:nth-child(2) {
                padding-top: 1rem;
            }
            padding-bottom: 1rem;
            &-joined {
                display: block;
                padding-top: 0.25rem;
            }
        }
    }
    a {
        color: white;
        text-decoration: none;
    }
}
@media screen and (max-width: 1000px) {
.HomePage {
    &__users {
        display: none;
    }
    &__newreviews {
        display: none;
    }
}
}

@media screen and (max-width: 600px) {
    .HomePage {
        &__trending {
            display: none;
        }
        &__newarrivals {
            display: none;
        }
    }
}
    