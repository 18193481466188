.MainList {
    display: flex;
    justify-content: center;
}

.MainList__filters {
    margin-bottom: 1rem;
}

.MainList__filters label {
    background-color: black;
    color: white;
    margin-right: 0.25rem;
}

.MainList__main-table {
    width: 100%;
    max-width: 1200px;
    border-collapse: collapse;
    opacity: 0.9;
}

.GeraldineTheReviewCell {
    white-space: nowrap;
}

@media only screen and (max-width: 949px) {
    .MainList__main-table thead tr th:nth-child(3) {
        display: none;
    }
    .MainList__main-table tbody tr td:nth-child(3) {
        display: none;
    }
}
@media only screen and (max-width: 1023px) {
    .MainList__main-table thead tr th:nth-child(4) {
        display: none;
    }
    .MainList__main-table tbody tr td:nth-child(4) {
        display: none;
    }
}
@media only screen and (max-width: 579px) {
    .MainList__main-table thead tr th:nth-child(5) {
        display: none;
    }
    .MainList__main-table tbody tr td:nth-child(5) {
        display: none;
    }
    .MainList__main-table thead th:nth-child(2) {
        display: none;
    }

    .MainList__main-table tr td:nth-child(2) {
        display: none;
    }

}

@media only screen and (max-width: 400px) {
    .MainList__main-table {
        font-size: 0.75em;
    }
}

@media only screen and (max-width: 1199px) {
    .MainList__main-table thead tr th:nth-child(6) {
        display: none;
    }
    .MainList__main-table tbody tr td:nth-child(6) {
        display: none;
    }
}

  
.MainList__main-table thead th:first-child {
    padding-left: 1rem;
}

.MainList__main-table thead th {
    padding: 2px 5px;
    height: 2.5rem;
    text-align: left;
    background-color: black;
}

.MainList__main-table tbody tr {
    background-color: black;
    height: 2rem;
    font-size: 0.9em;
    cursor: pointer;
}

.MainList__main-table tr td:first-child {
    padding-left: 1rem;
}

.MainList__main-table tr:nth-child(odd) {
    background-color: #111;
}

.MainList__main-table tbody tr:hover {
    background-color: white;
    color: black;
}

.MainList__main-table tbody tr:hover svg {
    fill: black;
}

.MainList__main-table tr td:last-child {
    width:1%;
    white-space:nowrap;
}
.MainList__main-table tr td {
    padding: 2px 5px;
    white-space: nowrap;
}

.MainList__nothing-notice {
    background-color: black;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
}

.titleCell {
    width: 100%;
}
