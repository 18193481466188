.PlayerPage {
  display: flex;
  justify-content: center;
  &__container {
    width: 100%;
    max-width: 1200px;
    background-color: black;
  }

  &__title {
    display: block;
    padding-bottom: 13px;
    padding-left: 13px;
    padding-top: 2px;
    font-size: 2rem;
    background-color: black;
    opacity: 0.9;
  }

  &__report-issue {
    position: relative;
    width: 100%;
    height: 10px;
    &-cta {
      width: 100%;
      font-size: 0.8em;
      text-align: right;
      position: absolute;
      right: 15px;
      top: -14px;
      text-decoration: underline;
      cursor: pointer;
    }
    &.report-active {
      height: auto;
      padding-bottom:5px;
    }
  }

  &__info-row {
    width: 100%;
    background: #313639;
    opacity: 0.9;
    display: flex;
    height: 50px;
    align-items: center;
  }

  &__added {
    padding-left: 1rem;
  }

  &__genres {
    flex-grow: 1;
    text-align: right;
    padding-right: 1rem;
  }

  &__popularity {
    padding-left: 1rem;
    padding-top: 0.5rem;
    display: block;
    position: relative;
    top: 0.5rem;
    z-index: 2;
  }
  &__overview {
    padding-top:1rem;
    padding-left:32px;
    padding-right:32px;
    line-height: 2em;
    padding-bottom:2rem;
    font-size: 1.25em;
    display: block;
    background-color:black;
    opacity: 0.9;
  }

  &__imdb-container {
    min-width: 50px;
    height: 50px;
  }
  .ratingBox {
    height: 50px;
  }
  .ratingBox span {
    line-height: 50px;
    color: black;
  }
}
@media screen and (max-width: 767px) {
  .PlayerPage {
    font-size: 0.8em;
  }
}