.NewReview {
    padding-bottom: 2em;
    &__title {
        display: block;
        font-size: 2em;
        padding-bottom: 0.5em;
    }
    &__review {
        display: flex;
        min-height: 100px;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        &-info {
            display: flex;
            flex-direction: column;
            min-width: 175px;
            &-name {
                font-size: 1.25em;
                padding-bottom: 0.5em;
            }
        }
        &-text {
            width: 80%;
            &-input {
                width: 100%;
                height: 100%;
            }
        }
    }
    &__no-reviews {
        display: block;
    }
    &__submit {
        cursor: pointer;
        margin-left: 180px;
        margin-top: 0.5em;
        padding: 4px 8px;
        background-color: rgb(49, 54, 57);
        border-radius: 6px;
        display: inline-block;
    }
}

.error {
    border: 4px solid red;
}

@media screen and (max-width: 767px) {
    .NewReview__review {
        flex-direction: column;
        &-text {
            width: 100%;
            margin-top: 0.5rem;
        }
    }
    .NewReview__submit {
        margin-left: 0.5rem;
    }
}