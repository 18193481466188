.AllMovies {
    &__filter-container {
        display: flex;
        justify-content: center;
    }
    &__filters {
        width: 100%;
        position: relative;
        max-width: 1200px;
        background-color:#313639;
        padding-top: 24px;
        display: flex;
        flex-direction: column;
        &-reset {
            position: absolute;
            top: 8px;
            right: 16px;
            cursor: pointer;
            text-decoration: underline;
        }
    }
    &__filter-input {
        display: flex;
        align-items: flex-start;
        flex-grow: 1;
        padding: 0px 26px;
        justify-content: space-between;
        div:last-child {
            padding-right: none;
        }
        &-title {
            width: 30%;
            padding-right: 24px;
            box-sizing: border-box;
        }
        &-genre {
            padding-top: 5px;
            padding-right: 24px;
            width: 50%;
            label {
                margin-bottom: 5px;
                input {
                    margin: 0;
                    margin-right: 5px;
                    position: relative;
                    top: 1px;
                }
                font-size: 0.8em !important;
                white-space: nowrap;
            }
            display: grid; 
            gap: 5px; 
            grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));         
            }
        &-year {
            display: flex;
            flex-direction: column;
            &-title {
                margin-bottom: 13px;
                font-size: 1.5em;
            }
            &-container {
                max-width: 200px;
                padding-right: 0;
                display: grid;
                grid-template-columns: repeat(2, minmax(100px, 1fr));
                flex-direction: row;
            }
            &-label {
                width: 200px;
                font-size: 0.8em;
            }
        }
    }
    label {
        font-size: 1.5em;
        display: inline;
    }    
    &__input {
        width: 100%;
        height: 1em;
        font-size: 1.5em;
        display: inline-block;
        margin-top: 16px;
        border: 0;
    }
    &__view {
        display: flex;
        justify-content: space-between;
        &-style {

        }
        &-sort {
            &:hover {
                background: black;
            }
            text-align: right;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            position: relative;
            span {
                padding: 5px 8px;
            }
            &-menu {
                cursor: pointer;
                position: absolute;
                top: 100%;
                width: 100%;
                z-index: 2;
                background-color: black;
                padding: 0 0 24px;
                ul {
                    margin: 0;
                    padding: 0;
                    .active {
                        text-decoration: underline;
                    }
                    li {
                        padding-top: 8px;
                        padding-bottom: 8px;
                        list-style-type: none;
                        padding-right: 16px;
                        padding-left: 16px;
                        &:hover {
                            background: #313639;
                        }
                    }
                }
            }
        }
        &-label {
            font-size: 14px;
        }
        &-option {
            cursor: pointer;
            display: inline-block;
            border-bottom: none;
            background-color: #313639;
            color: white;
            padding: 5px 8px;
            box-sizing: border-box;
        }
        
        &-option.active {
            background: black;
        }
    }
}

@media screen and (max-width: 767px) {
    .AllMovies {
        &__input {
            padding: 0;
            border: none;
        }
        &__filter-input {
            flex-direction: column;
            display: block;
            margin-right: 0;
            div {
                padding-right: 0px;
            }
            &-title {
                padding: 0px !important;
                width: 100%;
            }
            &-genre {
                justify-content: space-evenly;
                width: 100%;
                padding: 0;
                padding-top: 14px;

            }
            &-year {
                padding-top: 13px;
                padding-bottom: 13px;
                width: 100%;
            }
        }
    }
}
