.PostDevlog {
    display: flex;
    justify-content: center;
    &__container {
        max-width: 1200px;
        width: 100%;
        background: black;
    }
    &__input {
        display: block;
        padding-top: 32px;
        input {
            display: block;
        }
        textarea {
            display: block;
        }
    }
}