.ReportProblem {
    height: 100%;
    width: 100%;
    padding-left: 1rem;
    box-sizing: border-box;
    padding-right: 1rem;
    display: flex;
    align-items: center;
    &__question {
        padding-right: 1rem;
    }
    &__text-input {
        flex-grow: 1;
    }
    &__submit {
        padding-left: 1rem;
        display: inline-block;
        cursor: pointer;
    }
}