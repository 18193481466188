.Register {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    height: -moz-available;          /* WebKit-based browsers will ignore this. */
    height: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
    height: stretch;
    &__container {
        max-width: 1200px;
        background-color: black;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 2em 3em;
    }
    li {
        padding-left: 0.5em;
    }
    &__rules {
        &-title {
            margin: 0;
        }
        ol {
            li {

            }
        }
    }
    &__newaccount {
        font-size: 1.2em;
        padding-top: 1em;
        &-input {
            &-warning {
                display: inline-block;
                padding-top: 0.75em;
            }
        }
        &-row {
            padding-bottom: 1em;
        }
        &-label {
            width: 100px;
            display: inline-block;
        }
        &-input {
            font-size: 1em;
            margin-top: 0.5em;
            display: block;
        }
        &-note {
            display: block;
            font-size: 0.7em;
            padding-top: 0.75em;
        }
        &-submit {
            padding: 4px 12px;
            font-size: 1em;
            background-color: #313639;
            display: inline-block;
            margin-top: 16px;
            cursor: pointer;
        }
    }
}