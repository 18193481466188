.Header {
    width: 100%;
    text-align: center;
}

.Header__title {
    background-color: black;
    font-size: 4rem;
    display: inline-block;
    margin-top: 3rem;
    margin-bottom: 1rem;
    padding-right:0.25rem;
    padding-left:0.25rem;
}

.Header__navigation {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    width: 100%;
    margin-bottom: 2rem;
}

.Header__link {
    background-color: black;
    color: white;
    font-size: 1.5rem;
    text-decoration: none;
    margin-left: 1rem;
    margin-right: 1rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    display: inline-block;
    margin-top: 0.25rem;
}

.Header__link span {
    white-space: nowrap;
}

.Header__link:visited {
    color: white;
}

.Header__link:hover {
    color: black;
    background-color: white;
}

@media (min-width: 320px) and (max-width: 769px) {

    .Header {
        text-align: center;
    }

    .Header__navigation {
        text-align: left;
        padding-left: 5%;
        padding-right: 5%;
        box-sizing: border-box;
    }

    .Header__link {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        box-sizing: border-box;
        text-align:center;
        display: inline-block;
    }

}