.UserName {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    a {
        display: flex !important;
        align-items: center;
        justify-content: center;            
    }
    &__name {
        margin-right: 4px;
        border-bottom: 2px solid white;
        &:hover {
            text-decoration: none;
        }
    }
    &__score {
        display: inline-block;
        background-color: white;
        color: black;
        font-size: 0.6em;
        padding: 0px 3px 1px 3px;
        border-radius: 4px;
        a {
            color: black !important;
        }
    }
    &__medal {
        height: 1em;
        width: 1em;
        margin-left: 0.2em;
    }
    a {
        color: white;
        text-decoration: none;
    }
    a:hover {
        color: none;
        text-decoration: none;
    }
    a:visited {
        color: none;
    }
}