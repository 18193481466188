.UserPage {
    display: flex;
    justify-content: center;
    &__container {
        padding-top: 1rem;
        padding-bottom: 2rem;
        width: 100%;
        max-width: 1200px;
        padding-left: 2rem;
        padding-right: 2rem;
        box-sizing: border-box;
        background-color: black;
    }
    &__profile {
        display: flex;
        align-items: center;
        &-name {
            font-size: 2em;
            .UserName__score {
                margin-left: 5px;
            }
        }
        &-joined {
            padding-left: 0.5em;
            padding-right: 0.5em;
            
        }
        .UserPage__medal {
            margin-left: 0.5em;
            margin-right: 0.5em;
            border-radius: 0.25em;
            padding-left: 0.25em;
            padding-right: 0.25em;
            background-color: white;
            color: black;
            position: relative;
        }
        .explainer {
            display: none;
        }
        .shownexplainer {
            display: block !important;
            position: absolute;
            top: 100%;
            background-color: black;
            padding: 0.25em;
            padding-bottom: 0.5em;
            width: 100%;
            box-sizing: border-box;
            color: white;
        }
    }
    &__score {
        padding-top: 3rem;
        padding-bottom: 4rem;
        font-size: 2rem;
        padding-left:2rem;
        padding-right:2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &-total {
            width: 16%;
            align-self: stretch;
            display: flex;
            align-items: center;
        }
        &-subtotal {
            align-self: stretch;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: space-evenly;
            width: 16%;
            font-size: 1rem;
            &-text {
                display: block;
                padding-bottom: 0.5rem;
            }
            &-figure {
                padding: 0.25rem;
                padding-left: 0.5rem;
                padding-right: 0.5rem;
                background: #313639;
                border-radius: 4px;
            }
        }
    }
    &__reviews {
        text-align: center;
        display: inline-block;
        min-width: 300px;
        width: 50%;
        &-title {
            background: white;
            color: black;
            padding: 0 5px 2px 5px;
            font-size: 1.5rem;
            margin-bottom: 1rem;
            display: inline-block;
        }
    }
    &__review {
        padding-bottom: 2.5rem;
        &-info {
            &-title {
                font-size:1.2em;
                display: block;       
            }
            &-date {
                display: block;
                padding-bottom: 0.5rem;
            }
        }
        &-text {
            padding-top: 0.5rem;
            line-height: 1.75em;
        }
    }
    &__details {
        display: flex;
        align-items: flex-start;
    }
    &__fetched {
        display: inline-block;
        min-width: 300px;
        text-align: center;
        width: 50%;
        &-title {
            background: white;
            color: black;
            padding: 0 5px 2px 5px;
            font-size: 1.5rem;
            margin-bottom: 1rem;
            display: inline-block;
        }
        &-movie {
            display: block;
            padding-bottom: 0.5rem;
            font-size: 1.2em;
        }
    }
    a {
        text-decoration: none;
        display: inline-block;
        span {
            border-bottom: 2px solid white;
        }
        &:hover {
            text-decoration: none;
        }
        color: white;
    }
    @media screen and (max-width: 767px) {
        &__profile {
            flex-direction: column;
            align-items: start;
        }
        &__medal {
            margin-left: 0px !important;
            margin-top: 0.25em;
        }
        &__profile-joined {
            padding-left: 0 !important;
        }
        &__details {
            flex-direction: column;
        }
        &__reviews {
            min-width: 100%;
            width: 100%;
            &-title {
                margin-top:10px
            }
        }
        &__fetched {
            padding-top: 2rem;
            min-width: 100%;
            width: 100%;
        }
        &__score {
            flex-direction: column;
            padding: 0px;
            padding-top: 16px;
            * {
                width: auto;
            }
            &-subtotal-text {
                padding-top: 2rem;
            }
        }
    }
}