@font-face {
  font-family: 'DiodrumSemiBold';
  src: url('/fonts/DiodrumSemiBold.woff2') format('woff2'), /* Super Modern Browsers */
       url('/fonts/DiodrumSemiBold.woff') format('woff'), /* Pretty Modern Browsers */
       url('/fonts/DiodrumSemiBold.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('/fonts/DiodrumSemiBold.svg#svgFontName') format('svg'); /* Legacy iOS */
}

html {
  height: 100%;
  background-color: black;
}

body {
  font-family: 'DiodrumSemiBold';
  background-image: url('/images/vhsoverlay.jpg');
  background-color: black;
  color: white;
  padding: 0;
  margin: 0;
  min-height: 100%;
}

#root {
  min-height: 100vh;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  &.DEVELOPMENT {
    border-top:2rem solid red;
    &::before {
      content: 'DEVELOPMENT';
      position: relative;
      top:-1.6rem;
      width: 100%;
      text-align: center;
    }
  }
}

.DEVELOPMENT {
  border-top:2rem solid red;
}