.GridList {
    display: flex;
    justify-content: center;
    &__overlay {
        display: none;
    }
    &__overlay-text {
        display: none;
    }
    &__container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        width: 100%;
        max-width: 1200px;
        padding-top: 16px;
        padding-bottom: 16px;
        background-color: black;
    }
    &__box {
        &:hover {
            .GridList__overlay {
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 265px;
                background: black;
                opacity: 0.8;
            }
            .GridList__overlay-text {
                display:block;
                width:100%;
                text-overflow:ellipsis;
                overflow:hidden;
                max-height:252px;
                padding:15px 17px;
                display: -webkit-box;
                -webkit-line-clamp: 6;
                -webkit-box-orient: vertical;
                position: absolute;
                box-sizing: border-box;
                top: 0;
                left: 0;
                width: 100%;
                height: 252px;
                overflow : hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 12;
                -webkit-box-orient: vertical;
            }
        }
    
        position: relative;
        cursor: pointer;
        width: 170px;
        height: auto;
        padding: 13px;
        &-contents {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            flex: 1 auto;
        }
        img {
            width: 100%;
            height: 252px;
        }
        &-info {
            height: 80px;
            width: 100%;
            display: flex;
            background-color: black;
        }
        &-stats {
            width:40px !important;
            height: 80px;
            flex-basis: 40px;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            align-items: space-between;
            justify-content: space-evenly;
        }
        &-title {
            text-align: center;
            font-size: 14px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: black;
            padding: 2px 5px 2px 5px;
            height: 80px;
            width: 130px;
            box-sizing: border-box;
        }
        &-IMDB {
            width: 100%;
            height: 50%;
            display: inline-block;
            div.ratingBox {
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            box-sizing: border-box;
        }
        &-country {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height:50%;
            box-sizing: border-box;
            background: #313639;
        }
        &-reviews {
            position: absolute;
            top: 0;
            left: 0;
            background-color: black;
            border-bottom-right-radius: 6px;
            padding: 0 8px 5px 2px;
            &-figure {
                display: block;
            }
        }
        &:hover {
            .GridList__box-reviews {
                display: none;
            }
        }
    }
}