.VHS {
    margin: 0;
    width: 100%;
    height: 0px;
    padding-bottom: 58.25%;
    background: #121212;
    position: relative;
    z-index: 1;
}
.leftbig {
    left: 5%;
}
.rightbig {
    right: 5%;
}
.bigcircle {
    width: 45%;
    height: 80%;
    border-radius: 100%;
    position: absolute;
    top: 12.5%;
    background: lightGray;
    z-index: 2;
}
.strut {
    width: 100%;
    background: #121212;
    position: absolute;
    z-index: 10;
}
.topstrut {
    height: 30%;
    top: 0;
}
.bottomstrut {
    height: 25%;
    bottom: 0;
}
.centerstrut {
    background: #121212;
    position: absolute;
    width: 40%;
    height: 100%;
    left: 30%;
    z-index: 10;
}
.centercircle {
    background: white;
    position: absolute;
    width: 22.5%;
    height: 40%;
    z-index: 5;
    border-radius: 100%;
}
.centercircleleft {
    top: 32.5%;
    left: 20%;
}
.centercircleright {
    top: 32.5%;
    right: 20%;
}
.spool {
    background: dimgray;
    position: absolute;
    z-index: 4;
    border-radius: 100%;
}
.leftspool {
    width: 45%;
    height: 80%;
    top: 13.25%;
    left: 10%;
    animation-duration: 0.5s;
    animation-name: changewidthright;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}
.rightspool {
    width: 25%;
    height: 45%;
    top: 30%;
    right: 17.5%;
    animation-duration: 0.5s;
    animation-name: changewidthleft;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}

@keyframes changewidthright {
    from {
        top: 13.25%;
        left: 10%;
        width: 45%;
        height: 80%;
    }

    to {
        width: 25%;
        height: 45%;
        top: 30%;
        left: 17.5%;
    }
}

@keyframes changewidthleft {
    from {
        width: 25%;
        height: 45%;
        top: 30%;
        right: 17.5%;
    }

    to {
        top: 13.25%;
        right: 10%;
        width: 45%;
        height: 80%;
    }
}
